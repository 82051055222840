<template>
  <div class="block d-flex flex-column justify-content-center align-items-center align-content-center" v-on:click="close" ref="wrapper">
    <div
        class="m-auto thumb-over d-flex flex-column justify-content-center align-items-center align-content-center"
        :style="style"
    >
      <video
          v-if="video"
          ref="video"
          width="100%"
          height="100%"
          autoplay
          controls
          loop
      >
        <source
            :src="video.path"
            :type="video.type"
        />
      </video>
      <b-container
        v-else-if="image"
        class="w-100"
      >
        <b-row>
          <b-col
            v-for="img in images"
            :key="img"
            :style="{'background-image': `url('${img}')`}"
            class="image-preview"
          >
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "thumbOver",
  props: {
    type: String,
    image: {
      gif: String,
      images: Array
    },
    video: {
      gif: String,
      path: String
    },
    event: MouseEvent,
    thumb: HTMLDivElement
  },
  data: () => ({
    width: 0,
    height: 0,
    videoShow: false,
    images: []
  }),
  created () {
    (async () => {
      await this.$nextTick()
      if (this.video) {
        const video = this.$refs.video
        video.onloadedmetadata = () => {
          const {top, left, width, height, bottom, x, y} = this.thumb.getBoundingClientRect()
          const {clientWidth, clientHeight, clientTop, clientLeft} = this.thumb
          const {pageX, pageY, offsetX, offsetY} = this.event
          this.top = pageY - offsetY - height
          this.left = pageX - offsetX - width
          this.videoShow = true
        }
      } else if (this.image) {
        const { gif, images } = this.image
        for (const image of images) {
          const img = new Image()
          img.onload = () => {
            const {width, height} = img
            const min = Math.min.apply(null, [width, height])
            const max = Math.max.apply(null, [width, height])
            const aspectRatio = min / max
            const { clientWidth, clientHeight } = document.documentElement

            const newWidth = clientWidth * aspectRatio
            const newHeight = clientHeight * aspectRatio
          //
          //   this.width = newWidth
          //   this.height = newHeight
            this.images.push(img.src)
          }
          img.src = image.path
        }
      }
    }) ()
  },
  computed: {
    style () {
      return {
        'background-image': `url('${this.media}')`
      }
    },
    media () {
      if (this.type === 'video' && this.video) {
        const { gif } = this.video
        return gif
      } else if (this.type === 'image' && this.image) {
        const { path } = this.image
        return path
      }
      return null
    }
  },
  methods: {
    close (evt) {
      const { target } = evt
      const wrapper = this.$refs.wrapper
      if (target === wrapper) this.$emit('close')
    }
  }
}
</script>

<style scoped>
.block {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.thumb-over {
  width: 50vw;
  height: 50vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
}
.image-preview {
  min-height: 400px;
  background-position: center;
  background-size: cover;
}
</style>
