<template>
  <main class="bg-light">
    <div>
      <keep-alive>
        <top-nav />
      </keep-alive>
    </div>
    <div
      v-if="pending"
      id="pending"
      class="d-flex flex-column justify-content-center align-items-center align-content-center"
    >
      <h1 class="text-danger">LOADING</h1>
      <b-spinner variant="danger" size="lg" />
    </div>
    <div v-else>
      <b-container>
        <b-row>
          <b-col class="p-4">
            <router-view v-on:load="load" />
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid class="mt-2">
        <b-row>
          <b-col>
            <b-table-simple id="list-table" bordered class="bg-white">
              <b-thead>
                <b-tr>
                  <b-th :colspan="columns.length">
                    <div>
                      <filter-component
                        :filters="filters"
                        :filterData="filter"
                        :searchFilterSelect="searchFilterSelect"
                        :selectedId="selectedId"
                        v-on:search="search"
                      />
                    </div>
                  </b-th>
                </b-tr>
                <b-tr>
                  <b-th v-for="(col, idx) in columns" :key="`${col}-${idx}`">
                    {{ col }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tfoot>
                <b-tr>
                  <b-th :colspan="columns.length">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <small class="font-weight-bold text-primary">
                        {{ total }}개가 검색되었습니다.
                      </small>
                      <b-pagination-nav
                        :link-gen="linkGen"
                        :number-of-pages="totalPage"
                        use-router
                        first-number
                        last-number
                        size="sm"
                        align="right"
                      />
                    </div>
                  </b-th>
                </b-tr>
              </b-tfoot>
              <b-tbody>
                <template v-for="item in list">
                  <specific
                    :key="`list-${item._id}`"
                    :selectedId="selectedId"
                    :_id="item._id"
                    :type="item.type"
                    :seq="item.seq"
                    :title="item.title"
                    :regUser="item.regUser"
                    :image="item.image"
                    :video="item.video"
                    :status="item.status"
                    :regDate="item.regDate"
                    :startDate="item.startDate"
                    :completeEndDate="item.completeEndDate"
                    :surveyStatus="item.surveyStatus"
                    :statusObject="status"
                    :surveyStatusObject="surveyStatus"
                    :filterData="filter"
                    :page="page"
                    :harmful="item.harmful"
                    :validationLabels="item.validationLabels"
                    :questionAddCount="item.questionAddCount"
                    :medias="item.medias"
                    :facialRecognition="item.facialRecognition"
                  />
                </template>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
        <b-row>
          <b-col> </b-col>
        </b-row>
      </b-container>
    </div>
  </main>
</template>
<script>
import filterComponent from "@/components/filter";
import specific from "@/pages/ad/specific";
export default {
  name: "index",
  components: {
    filterComponent,
    specific,
  },
  data: () => ({
    pending: false,
    page: 1,
    total: 1,
    totalPage: 1,
    columns: [
      "_id",
      "TITLE",
      "REG DATE",
      "START DATE / COMPLETE DATE",
      "USER",
      "STATUS / SURVEY STATUS",
      "TYPE",
      "Q_ADD",
      "FacialRecognition",
      /*"THUMB",*/
    ],
    list: [],
    status: null,
    surveyStatus: null,
    searchFilterSelect: {},
    filter: {
      searchFilter: null,
      searchValue: null,
      perPage: 10,
    },
    filters: [
      { text: "선택", value: null },
      { text: "SEQ", value: "seq" },
      { text: "TITLE", value: "title" },
      { text: "STATUS", value: "status" },
      { text: "SURVEY STATUS", value: "surveyStatus" },
      { text: "TYPE", value: "type" },
      { text: "FACIAL RECOGNITION", value: "facialRecognition" },
    ],
    selectedId: null,
  }),
  created() {
    (async () => {
      const { _id } = this.$route.params;
      if (_id) this.selectedId = _id;
      const { page, searchFilter, searchValue, perPage } = this.$route.query;
      if (page && !isNaN(+page)) this.page = +page;
      if (searchFilter) this.filter.searchFilter = searchFilter;
      if (searchValue) this.filter.searchValue = searchValue;
      if (perPage && !isNaN(+perPage)) this.filter.perPage = +perPage;
      await this.load();
    })();
  },
  methods: {
    async load() {
      this.pending = true;
      try {
        const { data } = await this.axios({
          url: "/admin/ad/list",
          method: "GET",
          params: {
            page: this.page,
            ...this.filter,
          },
        });
        const { result, page, total, totalPage, list, status, surveyStatus } =
          data;
        if (result) {
          this.status = status;
          this.surveyStatus = surveyStatus;
          this.page = page;
          this.total = total;
          this.totalPage = totalPage;
          this.list = list;
          this.searchFilterSelect = {
            surveyStatus,
            status,
          };
        }
      } catch (e) {}
      this.pending = false;
    },
    linkGen(pageNum) {
      const filter = [];
      filter.push(pageNum === 1 ? "" : `page=${pageNum}`);

      if (this.filter) {
        const filterData = Object.entries(this.filter)
          .filter(([k, v]) => v)
          .map(([k, v]) => {
            return `${k}=${v}`;
          });
        if (filterData.length) filter.push(...filterData);
      }
      return `?${filter.join("&")}`;
    },
  },
};
</script>

<style scoped lang="scss">
#pending {
  width: 100%;
  height: 80vh;
}
#list-table {
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.8rem;
  th,
  td {
    vertical-align: middle;
  }
}
</style>
