<template>
  <b-tr :class="selectedId === _id ? 'active' : ''">
    <b-td>
      <router-link :to="link"> {{ _id }} ({{ seq }}) </router-link>
    </b-td>
    <b-td>
      {{ title }}
    </b-td>
    <b-td>
      {{ new Date(regDate).toLocaleString() }}
    </b-td>
    <b-td>
      <div>
        {{ startDate ? new Date(startDate).toLocaleString() : "" }}
      </div>
      <div>
        {{ completeEndDate ? new Date(completeEndDate).toLocaleString() : "" }}
      </div>
    </b-td>
    <b-td>
      <div
        class="d-flex align-content-center flex-column align-items-center justify-content-center"
      >
        <pre class="p-0 m-0">{{ userInfo }}</pre>
      </div>
    </b-td>
    <b-td>
      <div class="d-flex justify-content-center">
        <div class="p-1">
          <b-dropdown
            right
            size="sm"
            :text="item.status"
            :variant="variantStatus(item.status)"
          >
            <b-dropdown-item
              v-for="option in statusOptions"
              :key="`status-options-${option.value}`"
              v-on:click="statusChange(option.value, item)"
            >
              {{ option.value }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="p-1">
          <b-dropdown
            right
            size="sm"
            :text="item.surveyStatus"
            :variant="variant(item.surveyStatus)"
          >
            <b-dropdown-item
              v-for="option in surveyStatusOptions"
              :key="`status-options-${option.value}`"
              v-on:click="surveyStatusChange(option.value, item)"
            >
              {{ option.value }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </b-td>
    <b-td>
      <!--      {{ type }}-->
      <div v-if="medias.video">video ({{ medias.video }})</div>
      <div v-if="medias.image">image ({{ medias.image }})</div>
    </b-td>
    <b-td>
      <template v-if="questionAddCount > 0">
        <b-badge variant="primary"> +{{ questionAddCount }} 문항 </b-badge>
      </template>
      <template v-else> - </template>
    </b-td>
    <b-td>
      {{facialRecognition ? '사용' : ''}}
    </b-td>
    <!--    <b-td>
      <div
        ref="thumb"
        class="thumb"
        v-on:click="preview({ event: $event })"
        :style="{
          cursor: 'pointer',
          'background-image': `url('${media({ type, image, video })}')`,
        }"
      />
      <b-badge v-if="harmful" variant="danger"> LABEL 확인 </b-badge>
      <thumb-over
        v-if="event"
        :type="type"
        :image="image"
        :video="video"
        :event="event"
        :thumb="$refs.thumb"
        v-on:close="close"
      />
    </b-td>-->
  </b-tr>
</template>

<script>
import thumbOver from "./thumbOver";
export default {
  name: "specific",
  props: {
    selectedId: String,
    _id: String,
    type: String,
    seq: Number,
    title: String,
    image: {
      gif: String,
      images: Array,
    },
    video: Object,
    status: String,
    regDate: String,
    startDate: String,
    completeEndDate: String,
    regUser: {
      userId: String,
      userName: String,
      userEmail: String,
    },
    surveyStatus: String,
    statusObject: Object,
    surveyStatusObject: Object,
    page: Number,
    filterData: {
      searchFilter: String,
      searchValue: String,
    },
    harmful: Boolean,
    questionAddCount: Number,
    medias: {
      type: Object,
      default() {
        return {
          video: 0,
          image: 0,
        };
      },
    },
    facialRecognition: Boolean
  },
  components: {
    //thumbOver,
  },
  computed: {
    link() {
      const link = [];
      if (this.page) link.push(`page=${this.page}`);
      if (this.filterData) {
        const linkData = Object.entries(this.filterData)
          .filter(([k, v]) => v)
          .map(([k, v]) => `${k}=${v}`);
        link.push(...linkData);
      }
      return `/ad/list/${this._id}` + (link.length ? `?${link.join("&")}` : "");
    },
    statusOptions() {
      return Object.entries(this.statusObject).map(([k, v]) => {
        return {
          text: k,
          value: v,
        };
      });
    },
    surveyStatusOptions() {
      return Object.entries(this.surveyStatusObject).map(([k, v]) => {
        return {
          text: k,
          value: v,
        };
      });
    },
    userInfo() {
      if (this.regUser) {
        return Object.values(this.regUser)
          .filter((info) => info)
          .join("\r\n");
      }
      return "";
    },
  },
  data: () => ({
    item: {},
    event: null,
  }),
  created() {
    this.item = {
      status: this.status,
      surveyStatus: this.surveyStatus,
    };
  },
  methods: {
    media(item) {
      const { type, image, video } = item;
      if (type === "video" && video) {
        const { gif } = video;
        return gif;
      } else if (type === "image" && image) {
        const { gif } = image;
        return gif;
      }
      return null;
    },
    async statusChange(value, item) {
      if (!confirm(`광고 상태를 ${value}(으)로 변경합니다`)) return;
      try {
        const { data } = await this.axios({
          url: `/admin/ad/${this._id}/status`,
          method: "PATCH",
          data: {
            status: value,
          },
        });
        this.$toasted.success("변경되었습니다.", { duration: 3000 });
        item.status = value;
      } catch (e) {}
    },
    async surveyStatusChange(value, item) {
      if (!confirm(`실사 상태를 ${value}(으)로 변경합니다`)) return;
      try {
        const { data } = await this.axios({
          url: `/admin/ad/${this._id}/surveyStatus`,
          method: "PATCH",
          data: {
            surveyStatus: value,
          },
        });

        const { result, error } = data;
        if (result) {
          this.$toasted.success("변경되었습니다.", { duration: 3000 });
          item.surveyStatus = value;
        } else {
          this.$toasted.error(error, { duration: 6000 });
        }
      } catch (e) {}
    },
    variant(status) {
      if (status === "start") return "primary";
      else if (status === "end") return "success";
      return "secondary";
    },
    variantStatus(status) {
      if (status === "progress") return "primary";
      else if (status === "complete") return "success";
      else if (status === "reject") return "danger";
      return "secondary";
    },
    preview({ event }) {
      this.event = event;
      document.body.style.overflowY = "hidden";
    },
    close() {
      this.event = null;
      document.body.style.overflowY = "auto";
    },
  },
};
</script>

<style scoped>
tr.active {
  color: red;
  font-weight: bold;
  background-color: #f5f5f5;
}
tr > td {
  vertical-align: middle;
}
tr:hover {
  font-weight: bold;
  background-color: #f8f9fa;
}
.thumb {
  width: 5rem;
  height: 5rem;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
}
.thumb:hover {
  opacity: 0.3;
}
</style>
